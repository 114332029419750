import { sendToDataLayer } from '@utils';
const CONTACT_EVENT_CATEGORY = 'content_contact_us_section';
export enum ContactUsType {
  CallCenter = 'call_center',
  Email = 'email'
}
export const trackContactUsGoTo = () =>
  sendToDataLayer(undefined, {
    event_category: CONTACT_EVENT_CATEGORY,
    event_action: 'go_to',
    event_label: `help_center`
  });

export const trackContactUs = (contactType: ContactUsType) =>
  sendToDataLayer(undefined, {
    event_category: CONTACT_EVENT_CATEGORY,
    event_label: `${contactType}`
  });
