import { DefaultProps } from '../interface';
import { trackContactUs, trackContactUsGoTo, ContactUsType } from './tracking';

const ContactSection: React.FunctionComponent<DefaultProps> = ({ prefix }) => {
  const content = {
    th: [
      'คุณสามารถพบคำตอบสำหรับคำถามทั่วๆไปได้ที่ส่วนความช่วยเหลือ',
      'คลิกที่นี่',
      'หรือสามารถอีเมล์มาถามเราได้ที่',
      'หรือโทรหาเราที่',
      <>
        ไดรฟ์ฮับพยายามตอบทุกข้อสงสัยของคุณภายใน 24 ชม. ตลอดช่วงเวลาทำการของเรา
        <b>ทุกวัน 08:00 - 22:00 น.</b>
      </>
    ],
    en: [
      'For answers to general questions, please visit the Help section by',
      'clicking here',
      'If you need further assistance, feel free to contact us via email at',
      'or by calling us at',
      <>
        We are committed to providing timely responses to all inquiries within 24 hours during our operating hours,
        <b> which are from 08:00 AM to 10:00 PM every day</b>
      </>
    ]
  };

  const text = content[prefix];
  return (
    <div className="contact">
      <p>
        {text[0]}{' '}
        <a href={`/${prefix}/help-center`} onClick={trackContactUsGoTo}>
          {text[1]}
        </a>
        <br />
        {text[2]}{' '}
        <a href="mailto:support@drivehub.co" onClick={() => trackContactUs(ContactUsType.Email)}>
          support@drivehub.co
        </a>{' '}
        {text[3]}{' '}
        <a href="tel:020385222" onClick={() => trackContactUs(ContactUsType.CallCenter)}>
          02 038 5222
        </a>
      </p>
      <p>{text[4]}</p>
    </div>
  );
};

export default ContactSection;
